export default {
    // 列表中的订单
    orderList: [],
    loseFocusFlag: false,
    audio1: false,
    allScanOrderList: [],

    print_success: true,
    print_error: true,

    send_before_warehouse_list: [],
    send_before_warehouse_id: '',
    auto_send: false,

    cainiaoTemplateDetail: {
        cainiao_waybill_address_info: {
            shop_info: {
                name: ''
            },
            branch_name: '',
            branch_code: '',
            quantity: ''
        }
    },
    sumCount: 10,
    sumMer: 0,
    submitType: '1',
    scanCode: '',
    scanAction1: true,
    scanAction2: true,
    scanAction3: false,
    currentPrinterCainiao: '',
    printerListCainiao: [],
    templateId: '',
    templateList_cainiao: [],
    timer: null,
    orderListType: '所有已扫描' //当前订单池

}