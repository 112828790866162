import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
import { Cainiao } from "../../../extends/cainiao.class.js";


export default {
    init() {
        //
        // document.querySelector('body').onclick = function() {
        //     document.getElementById('audio1').play();
        // }
        // window.onload = () => {
        //     document.getElementById('scanCode').focus();
        //     this.timer = setInterval(() => {
        //         document.getElementById('scanCode').focus();
        //     }, 600);
        // };
        // 初始化菜鸟
        this.cainiao = new Cainiao();
        setTimeout(() => {
            this.printerListCainiao = this.cainiao.printerList;
        }, 1000);
        apis.getUserCainiaoTemplate().then((res) => {
            if (tools.msg(res, this)) {
                this.templateList_cainiao = res.data;
            }
        }).catch((error) => {
            tools.err(error, this);
        });

        // 获取默认仓库和仓库列表
        apis.borrowSource({

        }).then(res => {
            if (tools.msg(res, this)) {
                this.send_before_warehouse_list = res.data.warehouse;
            }
        }).catch(err => {
            tools.err(error, this);
        });


    },
    loseFocus() {
        this.loseFocusFlag = false;
    },
    getFocus() {
        this.loseFocusFlag = true;
    },
    // 扫码枪获得的订单id
    getScanCode() {
        let obj = {
            id: this.scanCode
        };
        let scanCode = this.scanCode;
        this.scanCode = '';

        // 
        if (!this.scanAction1 && !this.scanAction2 && !this.scanAction3) {
            this.$alert('请最少执行一项操作');
            return;
        }

        if (this.templateId === '') {
            this.$alert('请选择一个打印模板！');
            return;
        }
        if (!this.currentPrinterCainiao === '') {
            this.$alert('请选择一个打印机！');
            return;
        }
        // 根据提交方式，提交打印,单次提交，直接提交打印
        if (this.scanAction2) {
            if (this.submitType === '1') {
                this.printCainiaoSingle(scanCode);
            } else {
                this.sumOrder(scanCode);
            }
        }

        document.getElementById('audio0').play();
        // 显示订单列表
        this.scanAction1 && apis.orderDetail({
            id: scanCode
        }).then((res) => {
            if (tools.msg(res, this)) {
                let detail = res.data;
                if (!detail.title) {
                    return;
                }
                detail.title = JSON.parse(detail.title)[0].title;
                // 订单详情
                let num = 0;
                detail.list_info.forEach(item => {
                    num += item.num;
                });
                detail.all_num = num;
                this.$set(detail, 'print_num', 1);
                // 提交方式
                if (this.orderList.length > 0) {
                    if (this.submitType === '2') {
                        this.orderList.forEach(item => {
                            if (item.id == scanCode) {
                                //除重，累计打印次数
                                item.print_num++;
                            } else {
                                this.orderList.push(tools.copy(detail));
                            }
                        })
                    }
                } else {
                    this.orderList.push(tools.copy(detail));
                }
                // 所有的
                if (this.allScanOrderList.length > 0) {
                    this.allScanOrderList.forEach(item => {
                        if (item.id == scanCode) {
                            //除重，累计打印次数
                            item.print_num++;
                        } else {
                            this.allScanOrderList.push(tools.copy(detail));
                        }
                    })
                } else {
                    this.allScanOrderList.push(tools.copy(detail));
                }
            }
        }).catch((error) => {

            tools.err(error, this);
        });
    },
    // 对订单进行发货
    sendOrder(orders, flag) {
        // flag为true时，id是一个数组
        let arr = [];
        if (flag) {
            orders.forEach(item => {
                arr.push({
                    order_id: item.id
                });
            });
        } else {
            arr.push({
                order_id: orders
            });
        }

        // 订单发货操作
        apis.saveOrderSendStatus({
            orders: JSON.stringify(arr)
        }).then(res => {
            if (tools.msg(res, this)) {
                // this.reload();
            }
        }).catch(error => {
            tools.err(error, this);


        });
    },
    // 菜鸟打印
    printCainiaoSingle(id) {
        // 发货处理
        this.scanAction3 && this.sendOrder(id, false);
        // 请求打印对象
        let obj = {};
        obj[id] = {
            cainiao_templates_id: this.templateId,
            count: 1
        };
        let params = {
            orders: JSON.stringify(obj),
        };
        // 默认仓库
        if (this.auto_send) {
            params.warehouse_id = this.send_before_warehouse_id
        }
        // 请求打印数据，发送给打印机
        apis.canniaoPrintNewNumber(params).then((res) => {
            if (tools.msg(res, this)) {
                document.getElementById('audio1').play();
                this.preparePrint(res);
                return;
            }
            document.getElementById('audio2').play();
        }).catch((error) => {
            document.getElementById('audio2').play();
            tools.err(error, this);
        });
    },
    // 菜鸟打印
    printCainiaoMuptiple(orderList) {
        // 发货处理
        this.scanAction3 && this.sendOrder(orderList);
        // 请求打印对象
        let obj = {};
        orderList.forEach(item => {
            obj[item.id] = {
                cainiao_templates_id: this.templateId,
                count: item.print_num
            };
        });
        // 请求打印数据，发送给打印机
        apis.canniaoPrintNewNumber({
            orders: JSON.stringify(obj)
        }).then((res) => {
            if (tools.msg(res, this)) {
                if (tools.msg(res, this)) {
                    console.log(res);
                    this.preparePrint(res);
                }
            }
        }).catch((error) => {
            tools.err(error, this);
        });
    },

    // 准备打印工作
    preparePrint(res) {
        //发送请求给菜鸟打印机
        console.log(res);
        let index = 0;
        res.data[index].print_name = this.currentPrinterCainiao ? this.currentPrinterCainiao : res.data[index].print_name;
        this.cainiao.doPrint(res.data[index]);
        return;
        // let index = 0;
        // let timer = setInterval(() => {
        //     res.data[index].print_name = this.currentPrinterCainiao ? this.currentPrinterCainiao : res.data[index].print_name;
        //     this.cainiao.doPrint(res.data[index]);
        //     index++;
        //     if (index === res.data.length) {
        //         clearInterval(timer);
        //     }
        // }, 60);

    },

    // 累计模式
    sumOrder(id) {
        this.sumMer++;
        // 达到了目标长度，清空数组，并提交打印数据
        if (this.sumMer >= this.sumCount) {
            let orderList = [...this.orderList];
            // 提交打印数据
            this.orderList = [];
            this.printCainiaoMuptiple(orderList);
            this.sumMer = 0;
        }
    },

    submitOrderMutiple() {

    },
    // 修改菜鸟模版信息
    changeTemplate(item) {
        this.cainiaoTemplateDetail = item;
        this.currentPrinterCainiao = item.print_name;
        // 根据详情获取菜鸟账户信息
    },
    // 提交方式发生改变时，处理订单列表
    submitAction() {
        if (this.submitType == 1) {
            this.orderListType = '所有已扫描';
        } else {
            this.orderListType = '当前订单池';
        }
    }
}