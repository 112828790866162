<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <div >
                <audio id="audio0" :src="require('../../../assets/audio/滴.mp3')" v-if="print_success"></audio>
                <audio id="audio1" :src="require('../../../assets/audio/成功.mp3')" v-if="print_success"></audio>
                <audio id="audio2" :src="require('../../../assets/audio/订单异常.mp3')"  v-if="print_error"></audio>
            </div>

            <!-- 顶部导航 -->
            <el-card class="box-card" style="padding-bottom:70px;">
                <div style="display:flex;height:35px;line-height:35px;">
                    <div >
                        打印模板：<el-select v-model="templateId" 
                                    placeholder="请选择电子面单" size="small"  style="width:160px;">
                                    <el-option
                                    @click.native="changeTemplate(item)"
                                    v-for="item in templateList_cainiao"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                    </div>
                    <div style="margin-left:60px;">
                        打印机：<el-select v-model="currentPrinterCainiao" placeholder="请选择打印机" size="small" style="width:160px;">
                                    <el-option
                                    v-for="(item,key) in printerListCainiao"
                                    :label="item.name"
                                    :value="item.name"
                                    :key="key"></el-option>
                                </el-select>
                    </div>
                    
                    <div style="margin-left:60px;">
                        账户信息：
                        <span v-if="!templateId" style="color:blue;">选择面单后显示</span>
                        <span v-if="templateId>0">
                            <span style="display:inline-block;margin-left:5px;color:blue;" v-if="!!cainiaoTemplateDetail">
                                {{cainiaoTemplateDetail.cainiao_waybill_address_info.shop_info.name}} 
                                {{cainiaoTemplateDetail.cainiao_waybill_address_info.branch_name}} 
                                {{cainiaoTemplateDetail.cainiao_waybill_address_info.branch_code}} 
                            </span>
                            <span  style="display:inline-block;margin-left:25px;">
                                剩余 : <span style="color:#f00;">{{cainiaoTemplateDetail.cainiao_waybill_address_info.quantity}} </span> 单号
                            </span>
                        </span>

                    </div>
                </div>

                <!-- <div style="display:flex;height:45px;line-height:45px;margin-top:6px;">
                    <div >
                        勾选扫码枪执行的操作：
                        <el-checkbox v-model="scanAction1">获取订单信息</el-checkbox>
                        <el-checkbox v-model="scanAction2">打印电子面单</el-checkbox>
                        <el-checkbox v-model="scanAction3">发货（自己联系）</el-checkbox>
                    </div>
                </div> -->

                <div style="display:flex;margin-top:16px;">
                    <div style="position:relative;top:5px;margin-right:10px;">
                        自动发货：<el-switch v-model="auto_send" size="small"></el-switch>
                    </div>
                    <div >仓库：<el-select v-model="send_before_warehouse_id" :disabled="!auto_send" placeholder="请选择发货仓库" size="small" style="width:160px;">
                                        <el-option
                                        v-for="(item,key) in send_before_warehouse_list"
                                        :label="item.name"
                                        :value="item.id"
                                        :key="key"></el-option>
                                    </el-select>
                    </div>
                </div>
                <div style="display:flex;margin-top:16px;">
                    <div >
                        扫描-提交方式：
                            <el-radio v-model="submitType" label="1" @change="submitAction" size="small" border>单次提交（每次扫描都提交）</el-radio>
                            <!-- <el-radio v-model="submitType" label="2" @change="submitAction" size="small" border>累计次数提交</el-radio> -->
                    </div>
                    <div style="margin-left:20px;" v-if="submitType === '2'">
                        累计<el-input-number size="small" v-model="sumCount"></el-input-number>次后提交
                    </div>
                </div>

                <div style="margin-top:30px;position:relative;">
                    扫描过程请保持聚焦此输入框：
                    <input type="text" 
                            :style="{border:loseFocusFlag?'1px solid #ccc':'1px solid #f00'}"
                            v-model="scanCode"
                            autocomplete="off"
                            id="scanCode"
                            @focus="getFocus"
                            @blur="loseFocus"
                            @change="getScanCode" >
                    <div
                    v-show="!loseFocusFlag" 
                    style="width:auto;height:30px;line-height:30px;
                    color:#fff;margin-left:5px;
                    background:#f00;display:inline-block;padding:5px 10px;">请让输入框保持聚焦,否则无法进行扫描打印</div>
                </div>

                <div style="margin-top:30px;">
                    <div style="margin-bottom:15px;">
                        用扫码枪扫描订单编号/运单条形码/商家编码，快速执行设置好的操作
                        <el-radio-group v-model="orderListType" style="margin-left:10px;">
                            <el-radio-button label="所有已扫描"></el-radio-button>
                            <el-radio-button label="当前订单池" :disabled="submitType==1"></el-radio-button>
                        </el-radio-group>
                    </div>
                    <div v-show="orderListType == '所有已扫描'">
                        <table class="mytable">
                            <thead class="thead">
                                <tr class="rows ">
                                    <td class="cols">序号</td>
                                    <td class="cols">商品明细</td>
                                    <td class="cols">商品数量</td>
                                    <td class="cols">收货省市</td>
                                    <td class="cols">打印次数</td>
                                    <td class="cols">打印状态</td>
                                </tr>
                            </thead>
                            <tbody v-for="(i,k) in allScanOrderList" :key="k">
                                <tr class="rows " :class="k%2==0?'odd':''" >
                                    <td class="cols">{{k+1}}</td>
                                    <td class="cols">
                                        {{i.title}}
                                    </td>
                                    <td class="cols">{{i.all_num}}</td>
                                    <td class="cols">
                                        {{i.receiver_state}}
                                        {{i.receiver_city}}
                                    </td>
                                    <td class="cols">{{i.print_num}}</td>
                                    <td class="cols">
                                        <span v-if="!i.is_print_express">未打印</span>
                                        <span v-if="i.is_print_express===1">已打印</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-show="orderListType == '当前订单池'">
                        <table class="mytable">
                            <thead class="thead">
                                <tr class="rows ">
                                    <td class="cols">序号</td>
                                    <td class="cols">商品明细</td>
                                    <td class="cols">打印次数</td>
                                    <td class="cols">商品数量</td>
                                    <td class="cols">收货省市</td>
                                </tr>
                            </thead>
                            <tbody v-for="(i,k) in orderList" :key="k">
                                <tr class="rows " :class="k%2==0?'odd':''" >
                                    <td class="cols">{{k+1}}</td>
                                    <td class="cols">
                                        {{i.title}}
                                    </td>
                                    <td class="cols">{{i.print_num}}</td>
                                    <td class="cols">{{i.all_num}}</td>
                                    <td class="cols">
                                        {{i.receiver_state}}
                                        {{i.receiver_city}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </el-card>
        </div>
    </div>
</template>

<script>
import fieldConfig from './fieldConfig';
import methods from './method';
import objectData from './objectData';
import mergeOrderStrategy from '../dialogComponents/mergeOrderStrategy.vue';
import splitOrder from '../dialogComponents/splitOrder.vue';
import shipments from '../dialogComponents/shipments.vue';
import prepare from '../dialogComponents/prepare.vue';

export default {
    props:{
        //订单类型
        orderType:{
            type:String,
            default:'send'
        }
    },
    inject: ['selectSide'], 
    data(){
        return objectData;
    },
    components:{
        fieldConfig,mergeOrderStrategy,splitOrder,shipments,prepare
    },
    methods:methods,
    mounted(){
        this.init();
    },
    beforeDestroy(){
        clearInterval(this.timer);
    },
    computed: {
        cleanFieldList: ()=>{
            let newArr = [];
            objectData.fieldList.forEach((item,key)=>{
                if(item.show == 1){
                    newArr.push(item);
                }
            })
            return newArr;
        }
    }

}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
@import url('../less/order.less');
.cols{
    height:30px;
}
.cainiao_row{
    height:57px;line-height:60px;
}
.odd{
    .cols{
        background: #fafafa;
    }
}
#scanCode{
    // opacity: 0;position:absolute;width:5px;height:5px;
    border:1px solid #ccc;
}
</style>